import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { serverUrl, isAdmin, getYYYYMMDDext, getYYYYMMDD, toISOString, Spinner } from './App';

function NuovoProgetto(props) {
    const { aziendaId } = useParams();
    const [progettoLocal, setProgettoLocal] = useState({});
    const dispatch = useDispatch();

    return (
        <div className="modal fade" id={props.id} tabIndex="-1" aria-labelledby={props.id + 'Label'} aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id={props.id + 'Label'}>Nuovo progetto</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="form-group row">
                                <label htmlFor="nome" className="col-md-4 col-form-label">Identificativo</label>
                                <div className="col-md-8">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Identificativo"
                                        id="nome"
                                        value={progettoLocal?.nome || ''}
                                        onChange={(event) => setProgettoLocal({ ...progettoLocal, nome: event.target.value })}
                                    >
                                    </input>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="descrizione">Descrizione</label>
                                <textarea
                                    className="form-control"
                                    id="descrizione"
                                    rows="3"
                                    value={progettoLocal?.descrizione || ''}
                                    onChange={(event) => setProgettoLocal({ ...progettoLocal, descrizione: event.target.value })}
                                />
                            </div>
                            <div className="form-group row">
                                <label htmlFor="sede" className="col-md-4 col-form-label">Sede</label>
                                <div className="col-md-8">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Sede"
                                        id="sede"
                                        value={progettoLocal?.sede || ''}
                                        onChange={(event) => setProgettoLocal({ ...progettoLocal, sede: event.target.value })}
                                    >
                                    </input>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="decretoMise" className="col-md-4 col-form-label">Decreto Mise</label>
                                <div className="col-md-8">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        id="decretoMise"
                                        value={progettoLocal?.decretoMise || ''}
                                        onChange={(event) => setProgettoLocal({ ...progettoLocal, decretoMise: event.target.value })}
                                    >
                                    </input>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={(event) => {
                            event.preventDefault();
                            dispatch({ type: 'SET_DATA', spinner: true });
                            let security = JSON.parse(sessionStorage.getItem('security'));
                            axios({
                                method: 'POST',
                                headers: {
                                    'Accept': 'application/json, text/plain, */*',
                                    'Authorization': 'Bearer ' + security.token
                                },
                                url: serverUrl + '/aziende/' + aziendaId + '/progetto',
                                data: progettoLocal
                            })
                                .then((response) => {
                                    setProgettoLocal({});
                                    dispatch({ type: 'RESET' });
                                })
                                .catch(error => {
                                    dispatch({ type: 'SET_DATA', spinner: false });
                                    console.error("[STF] error:", error);
                                    toast("Attenzione, operazione NON completata");
                                });
                        }}><i className="fas fa-cloud-upload-alt"></i> Salva</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NuovoProgetto;