import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Header from './Header';
import NuovoDipendente from './NuovoDipendente';
//import ModificaDipendente from './ModificaDipendente';
import NuovoProgetto from './NuovoProgetto';
import CaricaFoglioPresenze from './CaricaFoglioPresenze';
import { serverUrl, isAdmin, getYYYYMMDDext, getYYYYMMDD, toISOString, Spinner } from './App';

function Warning(props) {
  let initialValue = <span />;
  return (
    props?.progetto?.attivitaEntityList
      ?.filter(attivita => attivita.warning)
      ?.reduce((accumulator, currentValue) => <span className="badge text-bg-danger">ERRORI</span>, initialValue)
  );
}

function Azienda() {
  const { aziendaId } = useParams();
  const aziende = useSelector(state => state?.aziende);
  const azienda = aziende?.filter(item => item.id == aziendaId)[0];
  const [aziendaLocal, setAziendaLocal] = useState(azienda);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (aziende === null) {
      if (sessionStorage.getItem('security')) {
        dispatch({ type: 'SET_DATA', spinner: true });
        let security = JSON.parse(sessionStorage.getItem('security'));
        axios({
          method: 'GET',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Authorization': 'Bearer ' + security.token
          },
          url: serverUrl + '/aziende'
        })
          .then((response) => {
            dispatch({ type: 'SET_DATA', spinner: false, ...response.data });
          })
          .catch(error => {
            console.error("[STF] error:", error);
          });
      }
    }
  });

  useEffect(() => {
    setAziendaLocal(azienda);
  }, [azienda]);

  return (
    <>
      <Header />
      <ToastContainer />
      <Spinner />
      <CaricaFoglioPresenze id={"CaricaFoglioPresenze"} />
      <nav aria-label="breadcrumb">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
            <li className="breadcrumb-item" aria-current="page">azienda {azienda?.ragioneSociale}</li>
          </ol>
        </div>
      </nav>
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Azienda</h5>
                <form>
                  <div className="form-group">
                    <label htmlFor="nome">Ragione sociale</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Ragione sociale"
                      id="nome"
                      value={aziendaLocal?.ragioneSociale || ''}
                      onChange={(event) => setAziendaLocal({ ...aziendaLocal, ragioneSociale: event.target.value })}
                    >
                    </input>
                  </div>
                  <div className="form-group">
                    <label htmlFor="realmRole">Realm Role</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Realm Role"
                      id="realmRole"
                      disabled={!isAdmin(useSelector(state => state.jwt))}
                      value={aziendaLocal?.realmRole || ''}
                      onChange={(event) => setAziendaLocal({ ...aziendaLocal, realmRole: event.target.value })}
                    >
                    </input>
                  </div>

                  <div className="form-group">
                    <button type="submit" className="btn btn-primary" onClick={(event) => {
                      event.preventDefault();
                      dispatch({ type: 'SET_DATA', spinner: true });
                      let security = JSON.parse(sessionStorage.getItem('security'));
                      let { dipendentiList, ...data } = aziendaLocal;
                      axios({
                        method: 'POST',
                        headers: {
                          'Accept': 'application/json, text/plain, */*',
                          'Authorization': 'Bearer ' + security.token
                        },
                        url: serverUrl + '/aziende',
                        data
                      })
                        .then((response) => {
                          toast("Operazione conclusa con successo");
                          dispatch({ type: 'RESET' });
                        })
                        .catch(error => {
                          dispatch({ type: 'SET_DATA', spinner: false });
                          console.error("[STF] error:", error);
                          toast("Attenzione, operazione NON completata");
                        });
                    }}><i className="fas fa-cloud-upload-alt"></i> Salva</button>&nbsp;&nbsp;

                    <button type="submit" className="btn btn-primary" disabled={!isAdmin(useSelector(state => state.jwt))} onClick={(event) => {
                      event.preventDefault();
                      dispatch({ type: 'SET_DATA', spinner: true });
                      let security = JSON.parse(sessionStorage.getItem('security'));
                      axios({
                        method: 'DELETE',
                        headers: {
                          'Accept': 'application/json, text/plain, */*',
                          'Authorization': 'Bearer ' + security.token
                        },
                        url: serverUrl + '/aziende/' + aziendaId
                      })
                        .then((response) => {
                          toast("Operazione conclusa con successo");
                          dispatch({ type: 'RESET' });
                          navigate('/');
                        })
                        .catch(error => {
                          dispatch({ type: 'SET_DATA', spinner: false });
                          console.error("[STF] error:", error);
                          toast("Attenzione, operazione NON completata");
                        });
                    }}><i className="fa fa-trash"></i> Elimina questa azienda</button>&nbsp;&nbsp;
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Dipendenti</h5>
                <ul className="list-group list-group-flush">
                  {azienda
                    ?.dipendentiList
                    ?.sort((a, b) => a.id - b.id)
                    ?.map((item, index) =>
                      <li key={'1_' + index} className="list-group-item">
                        <Link key={'2_' + index} to={"/aziende/" + azienda?.id + "/dipendente/" + item.id}>{item.cognome} {item.nome}</Link>
                      </li>
                    )}
                </ul>
                <NuovoDipendente id={"nuovoDipendente"} />
                <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target={"#nuovoDipendente"}>
                  Nuovo dipendente
                </button>
                &nbsp;
                <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target={"#CaricaFoglioPresenze"}>
                  Carica Foglio Presenze
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Progetti</h5>
                <ul className="list-group list-group-flush">
                  {azienda
                    ?.progettiList
                    ?.sort((a, b) => a.id - b.id)
                    ?.map((progetto, index) =>
                      <li key={'1_' + index} className="list-group-item">
                        <Link key={'2_' + index} to={"/aziende/" + azienda?.id + "/progetto/" + progetto.id}>{progetto.nome}</Link>
                        &nbsp;&nbsp;
                        <Warning progetto={{ ...progetto }} />
                      </li>
                    )}
                </ul>
                <NuovoProgetto id={"nuovoProgetto"} />
                <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target={"#nuovoProgetto"}>
                  Nuovo progetto
                </button>
                &nbsp;&nbsp;
                <button type="submit" className="btn btn-primary" onClick={(event) => {
                  event.preventDefault();
                  dispatch({ type: 'SET_DATA', spinner: true });
                  let security = JSON.parse(sessionStorage.getItem('security'));
                  axios({
                    method: 'GET',
                    responseType: 'blob', // important
                    headers: {
                      'Accept': 'application/json, text/plain, */*',
                      'Authorization': 'Bearer ' + security.token
                    },
                    url: serverUrl + '/aziende/' + aziendaId + '/zip',
                  })
                    .then((response) => {
                      toast("Operazione conclusa con successo");
                      dispatch({ type: 'RESET' });

                      // create file link in browser's memory
                      const href = URL.createObjectURL(response.data);

                      // create "a" HTML element with href to file & click
                      const link = document.createElement('a');
                      link.href = href;
                      link.setAttribute('download', azienda?.ragioneSociale + '.zip');
                      document.body.appendChild(link);
                      link.click();

                      // clean up "a" element & remove ObjectURL
                      document.body.removeChild(link);
                      URL.revokeObjectURL(href);
                    })
                    .catch(error => {
                      console.error("[STF] error:", error);
                      toast("Attenzione, operazione NON completata");
                      dispatch({ type: 'SET_DATA', spinner: false });
                    });
                }}>Aggiorna Timesheet e Scarica Zip</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Azienda;
