import queryString from 'query-string';
import axios from 'axios';
import qs from 'qs';
import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';

class Auth {
    props = {};

    constructor(props) {
        this.props = { ...this.props, ...props };
        this.login = this.login.bind(this);
        this.handleAuthentication = this.handleAuthentication.bind(this);
        this.logout = this.logout.bind(this);
    }

    login() {
        sessionStorage.removeItem('security');
        sessionStorage.setItem('redirectUri', window.location.href);

        var url = this.props.authorize
            + "?response_type=code"
            + "&client_id=" + this.props.clientId
            //+ "&client_secret=" + this.props.client_secret
            + "&scope=" + (this.props.scope ? this.props.scope : "openid profile")
            + "&redirect_uri=" + (this.props.redirect_uri ? this.props.redirect_uri : window.location.origin + window.location.pathname)

        if (this.props?.code_challenge_method === "S256") {
            var code_verifier = '' + Date.now() + Date.now() + '' + Date.now() + Date.now() + Date.now() + '' + Date.now();
            sessionStorage.setItem('code_verifier', code_verifier);
            var code_challenge = Base64.stringify(sha256(code_verifier))
                .replace(/\+/g, '-')
                .replace(/\//g, '_')
                .replace(/=/g, '');
            sessionStorage.setItem('code_challenge', code_challenge);

            url += "&code_challenge_method=S256&code_challenge=" + code_challenge
            window.location.href = url;
        }
        window.location.href = url;
    }

    logout(accessToken, refreshToken, idToken) {
        sessionStorage.setItem('redirectUri', window.location.href);
        if (this.props.vendor === "keycloak") {
            axios({
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'content-type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + accessToken
                },
                data: qs.stringify({
                    client_id: this.props.clientId,
                    //client_secret: this.props.client_secret,
                    refresh_token: refreshToken
                }),
                url: this.props.logout,
            })
                .then((response) => {
                    sessionStorage.removeItem('security');
                })
                .catch(error => {
                    sessionStorage.removeItem('security');
                    console.error("[STF] error:", error);
                });
        } else if (this.props.vendor === "wso2") {
            axios({
                method: 'GET',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Authorization': 'Bearer ' + accessToken
                },
                data: qs.stringify({
                    client_id: this.props.clientId,
                    //client_secret: this.props.client_secret,
                    refresh_token: refreshToken
                }),
                url: this.props.userinfo,
            })
                .then((response) => {
                    if (response?.data?.preferred_username || response?.data?.sub) {
                        var url = this.props.logout
                            + "?id_token_hint=" + idToken
                            //+ "&state=13e2312637dg136e1"
                            + "&post_logout_redirect_uri=" + window.location.origin + window.location.pathname;
                        window.location.href = url;
                    }
                })
                .catch(error => {
                    console.error("[STF] error:", error);
                });
        } else if (this.props.vendor === "openAM") {
            axios({
                method: 'GET',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Authorization': 'Bearer ' + accessToken
                },
                url: this.props.logout + "?id_token_hint=" + idToken,
            })
                .then((response) => {
                    console.log("[STF] logout eseguito con successo", response);
                })
                .catch(error => {
                    console.error("[STF] error:", error);
                });
        }
    }

    handleAuthentication() {
        const authResult = queryString.parse(window.location.search);
        if (authResult.code) {
            console.log("[STF] Auth.handleAuthentication() 1, handle login callback");
            let data = {
                grant_type: 'authorization_code',
                code: authResult.code,
                client_id: this.props.clientId,
                //client_secret: this.props.client_secret,
                redirect_uri: this.props.redirect_uri ? this.props.redirect_uri : window.location.origin + window.location.pathname
            }
            if (this.props?.code_challenge_method === "S256") {
                data['code_verifier'] = sessionStorage.getItem('code_verifier');
            }
            console.log("[STF] Auth.handleAuthentication() 2, POST ...");
            axios({
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'content-type': 'application/x-www-form-urlencoded'
                },
                data: qs.stringify({ ...data }),
                url: this.props.token,
            })
                .then((response) => {
                    console.log("[STF] Auth.handleAuthentication() 3, persist data");
                    const accessTokenDecoded = this.parseJwt(response?.data?.access_token);
                    const security = {
                        authHeader: response?.data?.token_type + " " + response?.data?.access_token,
                        errorCause: null,
                        expires: accessTokenDecoded?.exp,
                        loginError: null,
                        refresh_token: response?.data?.refresh_token,
                        token: response?.data?.access_token,
                        id_token: response?.data?.id_token,
                        user: {
                            enabled: true,
                        }
                    }
                    sessionStorage.setItem('security', JSON.stringify(security));
                    if (sessionStorage.getItem('redirectUri')) {
                        console.log("[STF] Auth.handleAuthentication() 4, replace url");
                        window.location.replace(sessionStorage.getItem('redirectUri'));
                    }
                })
                .catch(error => {
                    console.error("[STF] error(1):", error);
                });

        } else if (authResult.sp) {
            console.log("[STF] Auth.handleAuthentication() handle logout callback");
            if (sessionStorage.getItem('redirectUri')) {
                window.location.replace(sessionStorage.getItem('redirectUri'));
            }
        }
    }

    refreshToken() {
        if (sessionStorage.getItem('security')) {
            setInterval(() => {
                console.log("[STF] Auth.refreshToken()");
                let security = JSON.parse(sessionStorage.getItem('security'));
                const accessTokenDecoded = this.parseJwt(security?.token);
                const currentseconds = Math.floor(Date.now() / 1000);
                if (currentseconds > (accessTokenDecoded.exp - 60)) {
                    axios({
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'content-type': 'application/x-www-form-urlencoded',
                            //'Authorization': 'Bearer ' + accessToken
                        },
                        data: qs.stringify({
                            grant_type: 'refresh_token',
                            client_id: this.props.clientId,
                            //client_secret: this.props.client_secret,
                            refresh_token: security.refresh_token
                        }),
                        url: this.props.token,
                    })
                        .then((response) => {
                            const accessTokenDecoded = window.auth.parseJwt(response?.data?.access_token);
                            const security = {
                                authHeader: response?.data?.token_type + " " + response?.data?.access_token,
                                errorCause: null,
                                expires: accessTokenDecoded?.exp,
                                loginError: null,
                                refresh_token: response?.data?.refresh_token,
                                token: response?.data?.access_token,
                                id_token: response?.data?.id_token,
                                user: {
                                    enabled: true,
                                }
                            }
                            sessionStorage.setItem('security', JSON.stringify(security));
                        })
                        .catch(error => {
                            console.error("[STF] error:", error);
                        });
                }
            }, 60000);
        }
    }

    // refreshToken(accessToken, refreshToken) {
    //     return axios({
    //         method: 'POST',
    //         headers: {
    //             'Accept': 'application/json',
    //             'content-type': 'application/x-www-form-urlencoded',
    //             //'Authorization': 'Bearer ' + accessToken
    //         },
    //         data: qs.stringify({
    //             grant_type: 'refresh_token',
    //             client_id: this.props.clientId,
    //             //client_secret: this.props.client_secret,
    //             refresh_token: refreshToken
    //         }),
    //         url: this.props.token,
    //     })
    // }

    userInfo(accessToken, refreshToken) {
        console.log("[STF] Auth.userInfo()");
        return axios({
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Authorization': 'Bearer ' + accessToken
            },
            data: qs.stringify({
                client_id: this.props.clientId,
                //client_secret: this.props.client_secret,
                refresh_token: refreshToken
            }),
            url: this.props.userinfo,
        })
    }

    parseJwt(token) {
        try {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            return JSON.parse(jsonPayload);
        } catch (error) {
            return '';
        }
    }
}

export default Auth;