import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Header from './Header';
import { serverUrl, isAdmin, getYYYYMMDDext, getYYYYMMDD, toISOString, Spinner } from './App';

function NuovaAziendaModal(props) {
  const [aziendaLocal, setAziendaLocal] = useState({});
  const dispatch = useDispatch();

  return (
    <div className="modal fade" id={props.id} tabIndex="-1" aria-labelledby={props.id + 'Label'} aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id={props.id + 'Label'}>Nuova azienda</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group">
                <label htmlFor="nome">Ragione sociale</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Ragione sociale"
                  id="nome"
                  value={aziendaLocal?.ragioneSociale || ''}
                  onChange={(event) => setAziendaLocal({ ...aziendaLocal, ragioneSociale: event.target.value })}
                >
                </input>
              </div>
              <div className="form-group">
                <label htmlFor="realmRole">Realm Role</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Realm Role"
                  id="realmRole"
                  value={aziendaLocal?.realmRole || ''}
                  onChange={(event) => setAziendaLocal({ ...aziendaLocal, realmRole: event.target.value })}
                >
                </input>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={(event) => {
              event.preventDefault();
              dispatch({ type: 'SET_DATA', spinner: true });
              let security = JSON.parse(sessionStorage.getItem('security'));
              axios({
                method: 'POST',
                headers: {
                  'Accept': 'application/json, text/plain, */*',
                  'Authorization': 'Bearer ' + security.token
                },
                url: serverUrl + '/aziende',
                data: aziendaLocal
              })
                .then((response) => {
                  setAziendaLocal({});
                  dispatch({ type: 'RESET' });
                })
                .catch(error => {
                  dispatch({ type: 'SET_DATA', spinner: false });
                  console.error("[STF] error:", error);
                  toast("Attenzione, operazione NON completata");
                });
            }}><i className="fas fa-cloud-upload-alt"></i> Salva</button>
          </div>
        </div>
      </div>
    </div>
  );
}

function Aziende() {
  const aziende = useSelector(state => state?.aziende);
  const [flag, setFlag] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (aziende === null) {
      if (sessionStorage.getItem('security')) {
        dispatch({ type: 'SET_DATA', spinner: true });
        let security = JSON.parse(sessionStorage.getItem('security'));
        axios({
          method: 'GET',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Authorization': 'Bearer ' + security.token
          },
          url: serverUrl + '/aziende',
        })
          .then((response) => {
            //let { progetti } = response.data;
            dispatch({ type: 'SET_DATA', spinner: false, ...response.data });
          })
          .catch(error => {
            console.error("[STF] error:", error);
            sessionStorage.removeItem('security');
            dispatch({ type: 'RESET' });
          });
      }
    }
  });

  useEffect(() => {
    if (flag) {
      setFlag(false);
      dispatch({ type: 'RESET' });
    }
  }, [flag]);


  return (
    <>
      <Header />
      <ToastContainer />
      <Spinner />
      <NuovaAziendaModal id={"nuovaAziendaModal"} />
      {
        isAdmin(useSelector(state => state.jwt)) &&
        <button className="btn btn-primary onRigthBottomCorner" data-bs-toggle="modal" data-bs-target={"#nuovaAziendaModal"}>
          <i className="fa fa-plus fa-5x" aria-hidden="true"></i>
        </button>
      }
      <nav aria-label="breadcrumb">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
          </ol>
        </div>
      </nav>
      <div className="container">
        <div className="row">
          {
            aziende?.map((azienda, index) =>
              <div key={'1_' + index} className="col-md-4 col-sm-12">
                <div key={'2_' + index} className="card">
                  <div key={'3_' + index} className="card-body">
                    <h5 key={'4_' + index} className="card-title">azienda <Link key={'5b_' + index} to={"/aziende/" + azienda?.id}>{azienda?.ragioneSociale}</Link></h5>
                    {/* <h6 key={'5_' + index} className="card-subtitle mb-2 text-body-secondary">{'Realm Role: '+azienda.realmRole}</h6> */}
                  </div>
                </div>
              </div>)
          }
        </div>
      </div>
    </>
  );
}

export default Aziende;
