const initialState = {
    spinner: false,
    jwt: null,
    aziende: null,
    progetti: null
};

const theReducer = (state = initialState, action) => {
    console.log("theReducer action -->", action);
    let { type, ...other } = action;
    switch (type) {
        case "RESET":
            return {
                ...initialState
            }
        case "SET_DATA":
            return {
                ...state,
                ...other
            }
        default:
            return state
    }
}

export default theReducer;