import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Header from './Header';
import { serverUrl, isAdmin, getYYYYMMDDext, getYYYYMMDD, toISOString, Spinner } from './App';
import { NuovaVoceModal, ModificaVoceModal } from './FoglioPresenze';
import { NuovaAltraAttivitaModal, ModificaAltraAttivitaModal } from './AltreAttivita';

function Dipendente() {
    const { aziendaId, dipendenteId } = useParams();
    const aziende = useSelector(state => state?.aziende);
    const azienda = aziende?.filter(item => item.id == aziendaId)[0];
    const dipendente = azienda?.dipendentiList?.filter(item => item.id == dipendenteId)[0];
    const [dipendenteLocal, setDipendenteLocal] = useState(dipendente);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (aziende === null) {
            if (sessionStorage.getItem('security')) {
                dispatch({ type: 'SET_DATA', spinner: true });
                let security = JSON.parse(sessionStorage.getItem('security'));
                axios({
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Authorization': 'Bearer ' + security.token
                    },
                    url: serverUrl + '/aziende'
                })
                    .then((response) => {
                        //let { progetti } = response.data;
                        dispatch({ type: 'SET_DATA', spinner: false, ...response.data });
                    })
                    .catch(error => {
                        console.error("[STF] error:", error);
                    });
            }
        }
    });

    useEffect(() => {
        setDipendenteLocal(dipendente);
    }, [dipendente]);

    return (
        <>
            <Header />
            <ToastContainer />
            <Spinner />
            <nav aria-label="breadcrumb">
                <div className="container-fluid">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item" aria-current="page"><Link to={"/aziende/" + azienda?.id}>azienda {azienda?.ragioneSociale}</Link></li>
                        <li className="breadcrumb-item" aria-current="page">dipendente {dipendente?.cognome} {dipendente?.nome}</li>
                    </ol>
                </div>
            </nav>

            <NuovaAltraAttivitaModal id={"nuovaAltraAttivitaModal"} />
            <NuovaVoceModal id={"nuovaVoceModal"} />
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">Dipendente</h5>
                            </div>
                            <div className="card-body">
                                <form>

                                    <div className="form-group row">
                                        <label htmlFor="codiceDipendente" className="col-md-2 col-form-label">Codice Dipendente</label>
                                        <div className="col-md-4">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="codiceDipendente"
                                                id="codiceDipendente"
                                                value={dipendenteLocal?.codiceDipendente || ''}
                                                onChange={(event) => setDipendenteLocal({ ...dipendenteLocal, codiceDipendente: event.target.value })}
                                            >
                                            </input>
                                        </div>
                                        <label htmlFor="monteOreLavorativePreviste" className="col-md-2 col-form-label">Monte ore lavorative previste</label>
                                        <div className="col-md-4">
                                            <input
                                                className="form-control"
                                                type="number"
                                                placeholder="Monte Ore Lavorative Previste"
                                                id="monteOreLavorativePreviste"
                                                value={dipendenteLocal?.monteOreLavorativePreviste || ''}
                                                onChange={(event) => setDipendenteLocal({ ...dipendenteLocal, monteOreLavorativePreviste: event.target.value })}
                                            >
                                            </input>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="cognome" className="col-md-2 col-form-label">Cognome</label>
                                        <div className="col-md-4">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Cognome"
                                                id="cognome"
                                                value={dipendenteLocal?.cognome || ''}
                                                onChange={(event) => setDipendenteLocal({ ...dipendenteLocal, cognome: event.target.value })}
                                            >
                                            </input>
                                        </div>
                                        <label htmlFor="nome" className="col-md-2 col-form-label">Nome</label>
                                        <div className="col-md-4">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Nome"
                                                id="nome"
                                                value={dipendenteLocal?.nome || ''}
                                                onChange={(event) => setDipendenteLocal({ ...dipendenteLocal, nome: event.target.value })}
                                            >
                                            </input>
                                        </div>
                                    </div>

                                    <fieldset className="form-group">
                                        <div className="row">
                                            <legend className="col-form-label col-md-2 pt-0">Qualifica</legend>
                                            <div className="col-md-4">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" id="gridRadios1" value="alta"
                                                        onChange={(event) => setDipendenteLocal({ ...dipendenteLocal, qualifica: event.target.value })}
                                                        checked={dipendenteLocal?.qualifica === 'alta'}
                                                    />
                                                    <label className="form-check-label" htmlFor="gridRadios1">Alta</label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" id="gridRadios2" value="media"
                                                        onChange={(event) => setDipendenteLocal({ ...dipendenteLocal, qualifica: event.target.value })}
                                                        checked={dipendenteLocal?.qualifica === 'media'}
                                                    />
                                                    <label className="form-check-label" htmlFor="gridRadios2">Media</label>
                                                </div>
                                                <div className="form-check disabled">
                                                    <input className="form-check-input" type="radio" id="gridRadios3" value="bassa"
                                                        onChange={(event) => setDipendenteLocal({ ...dipendenteLocal, qualifica: event.target.value })}
                                                        checked={dipendenteLocal?.qualifica === 'bassa'}
                                                    />
                                                    <label className="form-check-label" htmlFor="gridRadios3">Bassa</label>
                                                </div>
                                            </div>
                                            <label htmlFor="costoMedioOrario" className="col-md-2 col-form-label">Costo medio orario</label>
                                            <div className="col-md-4">
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    placeholder="Costo medio orario"
                                                    id="costoMedioOrario"
                                                    value={dipendenteLocal?.costoMedioOrario || ''}
                                                    onChange={(event) => setDipendenteLocal({ ...dipendenteLocal, costoMedioOrario: event.target.value })}
                                                >
                                                </input>
                                            </div>
                                        </div>
                                    </fieldset>

                                    <div className="form-group row">
                                        <label htmlFor="nome" className="col-md-2 col-form-label">Contratto</label>
                                        <div className="col-md-4">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Contratto"
                                                id="contratto"
                                                value={dipendenteLocal?.contratto || ''}
                                                onChange={(event) => setDipendenteLocal({ ...dipendenteLocal, contratto: event.target.value })}
                                            >
                                            </input>
                                        </div>
                                        <label htmlFor="nome" className="col-md-2 col-form-label">Categoria</label>
                                        <div className="col-md-4">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Categoria"
                                                id="categoria"
                                                value={dipendenteLocal?.categoria || ''}
                                                onChange={(event) => setDipendenteLocal({ ...dipendenteLocal, categoria: event.target.value })}
                                            >
                                            </input>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        &nbsp;&nbsp;
                                        <button type="submit" className="btn btn-primary" onClick={(event) => {
                                            event.preventDefault();
                                            dispatch({ type: 'SET_DATA', spinner: true });
                                            let security = JSON.parse(sessionStorage.getItem('security'));
                                            let { attivitaEntityList, foglioPresenzeList, assenzeList, ...data } = dipendenteLocal;
                                            axios({
                                                method: 'POST',
                                                headers: {
                                                    'Accept': 'application/json, text/plain, */*',
                                                    'Authorization': 'Bearer ' + security.token
                                                },
                                                url: serverUrl + '/aziende/' + aziendaId + '/dipendente',
                                                data
                                            })
                                                .then((response) => {
                                                    toast("Operazione conclusa con successo");
                                                    setDipendenteLocal({});
                                                    dispatch({ type: 'RESET' });
                                                })
                                                .catch(error => {
                                                    dispatch({ type: 'SET_DATA', spinner: false });
                                                    console.error("[STF] error:", error);
                                                    toast("Attenzione, operazione NON completata");
                                                });
                                        }}><i className="fas fa-cloud-upload-alt"></i> Salva</button>
                                        &nbsp;&nbsp;
                                        <button type="submit" className="btn btn-primary" onClick={(event) => {
                                            event.preventDefault();
                                            dispatch({ type: 'SET_DATA', spinner: true });
                                            let security = JSON.parse(sessionStorage.getItem('security'));
                                            axios({
                                                method: 'DELETE',
                                                headers: {
                                                    'Accept': 'application/json, text/plain, */*',
                                                    'Authorization': 'Bearer ' + security.token
                                                },
                                                url: serverUrl + '/aziende/' + aziendaId + '/dipendente/' + dipendenteId,
                                            })
                                                .then((response) => {
                                                    toast("Operazione conclusa con successo");
                                                    dispatch({ type: 'RESET' });
                                                    navigate('/aziende/' + aziendaId);
                                                })
                                                .catch(error => {
                                                    dispatch({ type: 'SET_DATA', spinner: false });
                                                    console.error("[STF] error:", error);
                                                    toast("Attenzione, operazione NON completata");
                                                });
                                        }}><i className="fa fa-trash"></i> Elimina questo dipendente</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div className="card" >
                            <div className="card-header">
                                <h5 className="card-title">Foglio Presenze</h5>

                                <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target={"#nuovaVoceModal"} >
                                    Aggiungi voce
                                </button>
                            </div>
                            <div className="card-body" style={{ maxHeight: "300px", overflowY: "scroll" }}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Giorno</th>
                                            <th scope="col">Causale</th>
                                            <th scope="col">Numero di ore</th>
                                            <th scope="col"></th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dipendente
                                            ?.foglioPresenzeList
                                            //?.sort((a, b) => a.id - b.id)
                                            ?.sort((a, b) => (new Date(a.giorno)).valueOf() - (new Date(b.giorno)).valueOf())
                                            ?.map((item, index) =>
                                                <tr key={'1_' + index}>

                                                    <td>
                                                        {getYYYYMMDDext(item.giorno)}
                                                    </td>
                                                    <td>
                                                        {item.causale}
                                                    </td>
                                                    <td>
                                                        {item.numeroOre}
                                                    </td>
                                                    <td>
                                                        {item.origine}
                                                    </td>
                                                    <td>
                                                        <ModificaVoceModal id={"modificaVoceModal" + index} datiLocal={{ ...item, giorno: getYYYYMMDD(item?.giorno) }} />
                                                        <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target={"#modificaVoceModal" + index}>
                                                            <i className="fa fa-pencil"></i>
                                                        </button>
                                                        &nbsp;
                                                        {item?.causale != 'Ore Ordinarie' &&
                                                            <button type="submit" className="btn btn-primary" onClick={(event) => {
                                                                event.preventDefault();
                                                                dispatch({ type: 'SET_DATA', spinner: true });
                                                                let security = JSON.parse(sessionStorage.getItem('security'));
                                                                axios({
                                                                    method: 'DELETE',
                                                                    headers: {
                                                                        'Accept': 'application/json, text/plain, */*',
                                                                        'Authorization': 'Bearer ' + security.token
                                                                    },
                                                                    url: serverUrl + '/aziende/' + aziendaId + '/dipendente/' + dipendenteId + '/fogliopresenze/' + item.id,
                                                                })
                                                                    .then((response) => {
                                                                        toast("Operazione conclusa con successo");
                                                                        dispatch({ type: 'RESET' });
                                                                        navigate('/aziende/' + aziendaId + '/dipendente/' + dipendenteId);
                                                                    })
                                                                    .catch(error => {
                                                                        dispatch({ type: 'SET_DATA', spinner: false });
                                                                        console.error("[STF] error:", error);
                                                                        toast("Attenzione, operazione NON completata");
                                                                    });
                                                            }}><i className="fa fa-trash"></i></button>
                                                        }
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div className="card" >
                            <div className="card-header">
                                <h5 className="card-title">Altre Attivita</h5>
                                <h8>Le ore qui imputate (come ad esempio i corsi di formazione) vengono sottratte alle ore assegnabili ai progetti/attivita</h8>
                                <br />
                                <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target={"#nuovaAltraAttivitaModal"}>
                                    Aggiungi altra attivita
                                </button>
                            </div>
                            <div className="card-body" style={{ maxHeight: "300px", overflowY: "scroll" }}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Giorno</th>
                                            <th scope="col">Attivita</th>
                                            <th scope="col">Numero di ore</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dipendente
                                            ?.assenzeList
                                            //?.sort((a, b) => a.id - b.id)
                                            ?.sort((a, b) => (new Date(a.giorno)).valueOf() - (new Date(b.giorno)).valueOf())
                                            ?.map((item, index) =>
                                                <tr key={'1_' + index}>

                                                    <td>
                                                        {getYYYYMMDDext(item.giorno)}
                                                    </td>
                                                    <td>
                                                        {item.motivoAssenza}
                                                    </td>
                                                    <td>
                                                        {item.numeroOreAssenza}
                                                    </td>
                                                    <td>
                                                        <ModificaAltraAttivitaModal id={"modificaAltraAttivitaModal" + index} datiLocal={{ ...item, giorno: getYYYYMMDD(item?.giorno) }} />

                                                        &nbsp;
                                                        <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target={"#modificaAltraAttivitaModal" + index}>
                                                            <i className="fa fa-pencil"></i>
                                                        </button>

                                                        &nbsp;
                                                        <button type="submit" className="btn btn-primary" onClick={(event) => {
                                                            event.preventDefault();
                                                            dispatch({ type: 'SET_DATA', spinner: true });
                                                            let security = JSON.parse(sessionStorage.getItem('security'));
                                                            axios({
                                                                method: 'DELETE',
                                                                headers: {
                                                                    'Accept': 'application/json, text/plain, */*',
                                                                    'Authorization': 'Bearer ' + security.token
                                                                },
                                                                url: serverUrl + '/aziende/' + aziendaId + '/dipendente/' + dipendenteId + '/assenza/' + item.id,
                                                            })
                                                                .then((response) => {
                                                                    toast("Operazione conclusa con successo");
                                                                    dispatch({ type: 'RESET' });
                                                                    navigate('/aziende/' + aziendaId + '/dipendente/' + dipendenteId);
                                                                })
                                                                .catch(error => {
                                                                    dispatch({ type: 'SET_DATA', spinner: false });
                                                                    console.error("[STF] error:", error);
                                                                    toast("Attenzione, operazione NON completata");
                                                                });
                                                        }}><i className="fa fa-trash"></i></button>
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dipendente;