import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Header from './Header';
import { serverUrl, isAdmin, getYYYYMMDDext, getYYYYMMDD, toISOString, Spinner } from './App';

function AttivitaDipendenteModal(props) {
  const { aziendaId, progettoId, attivitaId } = useParams();
  const aziende = useSelector(state => state?.aziende);
  const azienda = aziende?.filter(item => item.id == aziendaId)[0];
  const progetto = azienda?.progettiList?.filter(item => item.id == progettoId)[0];
  const attivita = progetto?.attivitaEntityList?.filter(item => item.id == attivitaId)[0];
  const [attivitaDipendenteLocal, setAttivitaDipendenteLocal] = useState({ dataInizio: getYYYYMMDD(attivita?.dataInizio), dataFine: getYYYYMMDD(attivita?.dataFine) });
  const dispatch = useDispatch();

  useEffect(() => {
    window.$("#dataInizio2" + props.index).datepicker({
      timepicker: false,
      dateFormat: 'yy-mm-dd',
    }).on('change', (event) => setAttivitaDipendenteLocal({ ...attivitaDipendenteLocal, dataInizio: event.target.value }));

    window.$("#dataFine2" + props.index).datepicker({
      timepicker: false,
      dateFormat: 'yy-mm-dd',
    }).on('change', (event) => setAttivitaDipendenteLocal({ ...attivitaDipendenteLocal, dataFine: event.target.value }));
  });

  return (
    <div className="modal fade" id={props.id} tabIndex="-1" aria-labelledby={props.id + 'Label'} aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id={props.id + 'Label'}>Assegnazione dipendente ad attivita</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group">
                <label htmlFor={"dataInizio2" + props.index}>Data Inizio</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Data Inizio"
                  id={"dataInizio2" + props.index}
                  value={attivitaDipendenteLocal?.dataInizio || ''}
                  maxLength="10"
                  onChange={(event) => setAttivitaDipendenteLocal({ ...attivitaDipendenteLocal, dataInizio: event.target.value })}
                >
                </input>
              </div>
              <div className="form-group">
                <label htmlFor={"dataFine2" + props.index}>Data Fine</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Data Fine"
                  id={"dataFine2" + props.index}
                  value={attivitaDipendenteLocal?.dataFine || ''}
                  onChange={(event) => setAttivitaDipendenteLocal({ ...attivitaDipendenteLocal, dataFine: event.target.value })}
                >
                </input>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={(event) => {
              event.preventDefault();
              dispatch({ type: 'SET_DATA', spinner: true });
              let security = JSON.parse(sessionStorage.getItem('security'));
              let { id, ...dip } = props.dipendente;
              let data = { ...attivitaDipendenteLocal, ...dip, dipendenteId: id, dataInizio: toISOString(attivitaDipendenteLocal.dataInizio), dataFine: toISOString(attivitaDipendenteLocal.dataFine) }
              axios({
                method: 'POST',
                headers: {
                  'Accept': 'application/json, text/plain, */*',
                  'Authorization': 'Bearer ' + security.token
                },
                url: serverUrl + '/aziende/' + aziendaId + '/progetto/' + progettoId + '/attivita/' + attivitaId + '/dipendente',
                data
              })
                .then((response) => {
                  toast("Operazione conclusa con successo");
                  dispatch({ type: 'RESET' });
                })
                .catch(error => {
                  dispatch({ type: 'SET_DATA', spinner: false });
                  console.error("[STF] error:", error);
                  toast("Attenzione, operazione NON completata");
                });
            }}><i className="fas fa-cloud-upload-alt"></i> Salva</button>
          </div>
        </div>
      </div>
    </div>
  );
}

function ModificaAttivitaDipendenteModal(props) {
  const { aziendaId, progettoId, attivitaId } = useParams();
  const aziende = useSelector(state => state?.aziende);
  const azienda = aziende?.filter(item => item.id == aziendaId)[0];
  const progetto = azienda?.progettiList?.filter(item => item.id == progettoId)[0];
  const attivita = progetto?.attivitaEntityList?.filter(item => item.id == attivitaId)[0];
  const [attivitaDipendenteLocal, setAttivitaDipendenteLocal] = useState(props.attivitaDipendente);
  const dispatch = useDispatch();

  useEffect(() => {
    window.$("#dataInizio3" + props.index).datepicker({
      timepicker: false,
      dateFormat: 'yy-mm-dd',
    }).on('change', (event) => setAttivitaDipendenteLocal({ ...attivitaDipendenteLocal, dataInizio: event.target.value }));

    window.$("#dataFine3" + props.index).datepicker({
      timepicker: false,
      dateFormat: 'yy-mm-dd',
    }).on('change', (event) => setAttivitaDipendenteLocal({ ...attivitaDipendenteLocal, dataFine: event.target.value }));
  });

  return (
    <div className="modal fade" id={props.id} tabIndex="-1" aria-labelledby={props.id + 'Label'} aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id={props.id + 'Label'}>Assegnazione dipendente ad attivita</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group">
                <label htmlFor={"dataInizio3" + props.index}>Data Inizio</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Data Inizio"
                  id={"dataInizio3" + props.index}
                  value={attivitaDipendenteLocal?.dataInizio || ''}
                  maxLength="10"
                  onChange={(event) => setAttivitaDipendenteLocal({ ...attivitaDipendenteLocal, dataInizio: event.target.value })}
                >
                </input>
              </div>
              <div className="form-group">
                <label htmlFor={"dataFine3" + props.index}>Data Fine</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Data Fine"
                  id={"dataFine3" + props.index}
                  value={attivitaDipendenteLocal?.dataFine || ''}
                  onChange={(event) => setAttivitaDipendenteLocal({ ...attivitaDipendenteLocal, dataFine: event.target.value })}
                >
                </input>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={(event) => {
              event.preventDefault();
              dispatch({ type: 'SET_DATA', spinner: true });
              let security = JSON.parse(sessionStorage.getItem('security'));
              let data = { ...attivitaDipendenteLocal, dataInizio: toISOString(attivitaDipendenteLocal.dataInizio), dataFine: toISOString(attivitaDipendenteLocal.dataFine) }
              axios({
                method: 'POST',
                headers: {
                  'Accept': 'application/json, text/plain, */*',
                  'Authorization': 'Bearer ' + security.token
                },
                url: serverUrl + '/aziende/' + aziendaId + '/progetto/' + progettoId + '/attivita/' + attivitaId + '/dipendente',
                data
              })
                .then((response) => {
                  toast("Operazione conclusa con successo");
                  dispatch({ type: 'RESET' });
                })
                .catch(error => {
                  dispatch({ type: 'SET_DATA', spinner: false });
                  console.error("[STF] error:", error);
                  toast("Attenzione, operazione NON completata");
                });
            }}><i className="fas fa-cloud-upload-alt"></i> Salva</button>
          </div>
        </div>
      </div>
    </div>
  );
}

function Attivita() {
  const { aziendaId, progettoId, attivitaId } = useParams();
  const aziende = useSelector(state => state?.aziende);
  const azienda = aziende?.filter(item => item.id == aziendaId)[0];
  const progetto = azienda?.progettiList?.filter(item => item.id == progettoId)[0];
  const attivita = progetto?.attivitaEntityList?.filter(item => item.id == attivitaId)[0];
  const [attivitaLocal, setAttivitaLocal] = useState({ ...attivita, dataInizio: getYYYYMMDD(attivita?.dataInizio), dataFine: getYYYYMMDD(attivita?.dataFine) });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let getDipendente = (id) => {
    return azienda?.dipendentiList?.filter(dip => dip.id == id)[0];
  }

  useEffect(() => {
    if (aziende === null) {
      if (sessionStorage.getItem('security')) {
        let security = JSON.parse(sessionStorage.getItem('security'));
        axios({
          method: 'GET',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Authorization': 'Bearer ' + security.token
          },
          url: serverUrl + '/aziende'
        })
          .then((response) => {
            //let { progetti } = response.data;
            dispatch({ type: 'SET_DATA', spinner: false, ...response.data });
          })
          .catch(error => {
            console.error("[STF] error:", error);
          });
      }
    }
  });

  useEffect(() => {
    setAttivitaLocal({ ...attivita, dataInizio: getYYYYMMDD(attivita?.dataInizio), dataFine: getYYYYMMDD(attivita?.dataFine) });
  }, [attivita]);

  useEffect(() => {
    window.$("#dataInizio").datepicker({
      timepicker: false,
      dateFormat: 'yy-mm-dd',
    }).on('change', (event) => setAttivitaLocal({ ...attivitaLocal, dataInizio: event.target.value }));

    window.$("#dataFine").datepicker({
      timepicker: false,
      dateFormat: 'yy-mm-dd',
    }).on('change', (event) => setAttivitaLocal({ ...attivitaLocal, dataFine: event.target.value }));
  });

  return (
    <>
      <Header />
      <ToastContainer />
      <Spinner/>

      <nav aria-label="breadcrumb">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
            <li className="breadcrumb-item" aria-current="page"><Link to={"/aziende/" + azienda?.id}>azienda {azienda?.ragioneSociale}</Link></li>
            <li className="breadcrumb-item" aria-current="page"><Link to={"/aziende/" + azienda?.id + "/progetto/" + progettoId}>progetto {progetto?.nome}</Link></li>
            <li className="breadcrumb-item" aria-current="page">attivita {attivita?.nome}</li>
          </ol>
        </div>
      </nav>
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Attivita'</h5>
                <form>
                  <div className="form-group row">
                    <label htmlFor="nome" className="col-md-4 col-form-label">Identificativo</label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Identificativo"
                        id="nome"
                        value={attivitaLocal?.nome || ''}
                        onChange={(event) => setAttivitaLocal({ ...attivitaLocal, nome: event.target.value })}
                      >
                      </input>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="descrizione">Descrizione</label>
                    <textarea
                      className="form-control"
                      id="descrizione"
                      rows="3"
                      value={attivitaLocal?.descrizione || ''}
                      onChange={(event) => setAttivitaLocal({ ...attivitaLocal, descrizione: event.target.value })}
                    >
                    </textarea>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-4">
                      <label htmlFor="oreApprovateQualificaAlta">Ore approvate qualifica alta</label>
                      <input
                        type="number"
                        className="form-control"
                        id="oreApprovateQualificaAlta"
                        value={attivitaLocal?.oreApprovateQualificaAlta || 0}
                        onChange={(event) => setAttivitaLocal({ ...attivitaLocal, oreApprovateQualificaAlta: event.target.value })}
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="oreApprovateQualificaMedia">Ore approvate qualifica media</label>
                      <input
                        type="number"
                        className="form-control"
                        id="oreApprovateQualificaMedia"
                        value={attivitaLocal?.oreApprovateQualificaMedia || 0}
                        onChange={(event) => setAttivitaLocal({ ...attivitaLocal, oreApprovateQualificaMedia: event.target.value })}
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="oreApprovateQualificaBassa">Ore approvate qualifica bassa</label>
                      <input
                        type="number"
                        className="form-control"
                        id="oreApprovateQualificaBassa"
                        value={attivitaLocal?.oreApprovateQualificaBassa || 0}
                        onChange={(event) => setAttivitaLocal({ ...attivitaLocal, oreApprovateQualificaBassa: event.target.value })}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="nome" className="col-md-4 col-form-label">Data Inizio</label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="YYYY-MM-DD"
                        id="dataInizio"
                        value={attivitaLocal?.dataInizio || ''}
                        maxLength="10"
                        onChange={(event) => setAttivitaLocal({ ...attivitaLocal, dataInizio: event.target.value })}
                      >
                      </input>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="nome" className="col-md-4 col-form-label">Data Fine</label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="YYYY-MM-DD"
                        id="dataFine"
                        value={attivitaLocal?.dataFine || ''}
                        onChange={(event) => setAttivitaLocal({ ...attivitaLocal, dataFine: event.target.value })}
                      >
                      </input>
                    </div>
                  </div>

                  <div className="form-group">
                    <button type="submit" className="btn btn-primary" onClick={(event) => {
                      event.preventDefault();
                      dispatch({ type: 'SET_DATA', spinner: false });
                      let security = JSON.parse(sessionStorage.getItem('security'));
                      let data = { ...attivitaLocal, dataInizio: toISOString(attivitaLocal.dataInizio), dataFine: toISOString(attivitaLocal.dataFine) }
                      delete data.dipendentiList;
                      axios({
                        method: 'POST',
                        headers: {
                          'Accept': 'application/json, text/plain, */*',
                          'Authorization': 'Bearer ' + security.token
                        },
                        url: serverUrl + '/aziende/' + aziendaId + '/progetto/' + progettoId + '/attivita',
                        data
                      })
                        .then((response) => {
                          toast("Operazione conclusa con successo");
                          dispatch({ type: 'RESET' });
                        })
                        .catch(error => {
                          dispatch({ type: 'SET_DATA', spinner: false });
                          console.error("[STF] error:", error);
                          toast("Attenzione, operazione NON completata");
                        });
                    }}><i className="fas fa-cloud-upload-alt"></i> Salva</button>&nbsp;&nbsp;

                    <button type="submit" className="btn btn-primary" onClick={(event) => {
                      event.preventDefault();
                      dispatch({ type: 'SET_DATA', spinner: true });
                      let security = JSON.parse(sessionStorage.getItem('security'));
                      axios({
                        method: 'DELETE',
                        headers: {
                          'Accept': 'application/json, text/plain, */*',
                          'Authorization': 'Bearer ' + security.token
                        },
                        url: serverUrl + '/aziende/' + aziendaId + '/progetto/' + progettoId + '/attivita/' + attivitaId,
                      })
                        .then((response) => {
                          toast("Operazione conclusa con successo");
                          dispatch({ type: 'RESET' });
                          navigate('/aziende/' + aziendaId + '/progetto/' + progettoId);
                        })
                        .catch(error => {
                          dispatch({ type: 'SET_DATA', spinner: false });
                          console.error("[STF] error:", error);
                          toast("Attenzione, operazione NON completata");
                        });
                    }}><i className="fa fa-trash"></i> Elimina questa attivita</button>
                  </div>

                </form>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Dipendenti non assegnati</h5>
                <div className="row">
                  {azienda
                    ?.dipendentiList
                    ?.sort((a, b) => a.id - b.id)
                    ?.filter(item => attivita?.dipendentiList?.map(x => x?.dipendenteId).filter(x => x == item.id).length < 1)
                    ?.map((item, index) =>
                      <div key={index} className="col-4" style={{ marginTop: '5px' }}>
                        <AttivitaDipendenteModal dipendente={item} index={index} id={"attivitaDipendenteModal" + index} />
                        <button className="btn btn-warning" data-bs-toggle="modal" data-bs-target={"#attivitaDipendenteModal" + index}>
                          {item?.nome} {item?.cognome}
                        </button>
                      </div> 
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Dipendenti assegnati</h5>
                <div className="row">
                  {attivita
                    ?.dipendentiList
                    ?.sort((a, b) => a.id - b.id)
                    ?.map((item, index) =>
                      <div key={index} className="col-md-12 col-sm-12">
                        <div className="card">
                          <div className="card-body">
                            <h5 className="card-title">
                              <button className="btn btn-danger btn-sm" onClick={(event) => {
                                event.preventDefault();
                                dispatch({ type: 'SET_DATA', spinner: true });
                                let security = JSON.parse(sessionStorage.getItem('security'));
                                axios({
                                  method: 'DELETE',
                                  headers: {
                                    'Accept': 'application/json, text/plain, */*',
                                    'Authorization': 'Bearer ' + security.token
                                  },
                                  url: serverUrl + '/aziende/' + aziendaId + '/progetto/' + progettoId + '/attivita/' + attivitaId + '/dipendente/' + item?.dipendenteId,
                                })
                                  .then((response) => {
                                    toast("Operazione conclusa con successo");
                                    dispatch({ type: 'RESET' });
                                  })
                                  .catch(error => {
                                    dispatch({ type: 'SET_DATA', spinner: false });
                                    console.error("[STF] error:", error);
                                    toast("Attenzione, operazione NON completata");
                                  });
                              }}>
                                <i className="fa fa-trash"></i>
                              </button>
                              &nbsp;
                              <ModificaAttivitaDipendenteModal
                                id={"modificaAttivitaDipendenteModal" + index}
                                index={index}
                                attivitaDipendente={{ ...item, dataInizio: getYYYYMMDD(item?.dataInizio), dataFine: getYYYYMMDD(item?.dataFine) }}
                              />
                              <button className="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target={"#modificaAttivitaDipendenteModal" + index}><i className="fa-solid fa-pen"></i></button>
                              &nbsp;
                              {getDipendente(item?.dipendenteId)?.nome} {getDipendente(item?.dipendenteId)?.cognome}
                            </h5>
                            <p className="card-text">
                              Qualifica: {getDipendente(item?.dipendenteId)?.qualifica}<br />
                              da: {getYYYYMMDD(item?.dataInizio)}<br />
                              a: {getYYYYMMDD(item?.dataFine)}<br />
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  }
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Attivita;
