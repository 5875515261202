import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Header from './Header';
import { serverUrl, isAdmin, getYYYYMMDDext, getYYYYMMDD, toISOString, Spinner } from './App';

export function NuovaVoceModal(props) {
    const { aziendaId, dipendenteId } = useParams();
    const [datiLocal, setDatiLocal] = useState({ causale: 'Ore Ordinarie' });
    const dispatch = useDispatch();

    useEffect(() => {
        window.$("#giorno3").datepicker({
            timepicker: false,
            dateFormat: 'yy-mm-dd',
        }).on('change', (event) => setDatiLocal({ ...datiLocal, giorno: event.target.value }));
    });

    return (
        <div className="modal fade" id={props.id} tabIndex="-1" aria-labelledby={props.id + 'Label'} aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id={props.id + 'Label'}>Aggiungi voce</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="form-group">
                                <label htmlFor="giorno3">Giorno</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Giorno"
                                    id="giorno3"
                                    value={datiLocal?.giorno || ''}
                                    onChange={(event) => setDatiLocal({ ...datiLocal, giorno: event.target.value })}
                                >
                                </input>
                            </div>
                            <fieldset className="form-group">
                                <div className="row">
                                    <legend className="col-form-label col-md-4 pt-0">Causale</legend>
                                    <div className="col-md-8">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" id="gridRadios1" value="Ore Ordinarie"
                                                onChange={(event) => setDatiLocal({ ...datiLocal, causale: event.target.value })}
                                                checked={datiLocal?.causale === 'Ore Ordinarie'}
                                            />
                                            <label className="form-check-label" htmlFor="gridRadios1">Ore Ordinarie</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" id="gridRadios2" value="Malattia"
                                                onChange={(event) => setDatiLocal({ ...datiLocal, causale: event.target.value })}
                                                checked={datiLocal?.causale === 'Malattia'}
                                            />
                                            <label className="form-check-label" htmlFor="gridRadios2">Malattia</label>
                                        </div>
                                        <div className="form-check disabled">
                                            <input className="form-check-input" type="radio" id="gridRadios3" value="Ferie"
                                                onChange={(event) => setDatiLocal({ ...datiLocal, causale: event.target.value })}
                                                checked={datiLocal?.causale === 'Ferie'}
                                            />
                                            <label className="form-check-label" htmlFor="gridRadios3">Ferie</label>
                                        </div>
                                        <div className="form-check disabled">
                                            <input className="form-check-input" type="radio" id="gridRadios4" value="Permessi"
                                                onChange={(event) => setDatiLocal({ ...datiLocal, causale: event.target.value })}
                                                checked={datiLocal?.causale === 'Permessi'}
                                            />
                                            <label className="form-check-label" htmlFor="gridRadios4">Permessi</label>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <div className="form-group">
                                <label htmlFor="numeroOre">Numero di ore</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    placeholder="Numero di ore"
                                    id="numeroOre"
                                    value={datiLocal?.numeroOre || ''}
                                    onChange={(event) => setDatiLocal({ ...datiLocal, numeroOre: event.target.value })}
                                >
                                </input>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={(event) => {
                            try {
                                event.preventDefault();
                                dispatch({ type: 'SET_DATA', spinner: true });
                                let security = JSON.parse(sessionStorage.getItem('security'));
                                axios({
                                    method: 'POST',
                                    headers: {
                                        'Accept': 'application/json, text/plain, */*',
                                        'Authorization': 'Bearer ' + security.token
                                    },
                                    url: serverUrl + '/aziende/' + aziendaId + '/dipendente/' + dipendenteId + '/fogliopresenze',  // aziendaId, dipendenteId
                                    data: { ...datiLocal, giorno: toISOString(datiLocal.giorno) }
                                })
                                    .then((response) => {
                                        toast("Operazione conclusa con successo");
                                        setDatiLocal({});
                                        dispatch({ type: 'RESET' });
                                    })
                                    .catch(error => {
                                        dispatch({ type: 'SET_DATA', spinner: false });
                                        console.error("[STF] error:", error);
                                        toast("Attenzione, operazione NON completata");
                                    });
                            } catch (error) {
                                dispatch({ type: 'SET_DATA', spinner: false });
                                console.error("[STF] error:", error);
                                toast("Attenzione, operazione NON completata");
                            }
                        }}><i className="fas fa-cloud-upload-alt"></i> Salva</button>

                    </div>
                </div>
            </div>
        </div>
    );
}

export function ModificaVoceModal(props) {
    const { aziendaId, dipendenteId } = useParams();
    const [datiLocal, setDatiLocal] = useState(props?.datiLocal);
    const dispatch = useDispatch();

    useEffect(() => {
        window.$("#giorno4").datepicker({
            timepicker: false,
            dateFormat: 'yy-mm-dd',
        }).on('change', (event) => setDatiLocal({ ...datiLocal, giorno: event.target.value }));
    });

    return (
        <div className="modal fade" id={props.id} tabIndex="-1" aria-labelledby={props.id + 'Label'} aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id={props.id + 'Label'}>Modifica Voce</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="form-group">
                                <label htmlFor="giorno4">Giorno</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Giorno"
                                    id="giorno4"
                                    value={datiLocal?.giorno || ''}
                                    onChange={(event) => setDatiLocal({ ...datiLocal, giorno: event.target.value })}
                                    disabled
                                >
                                </input>
                            </div>
                            <div className="form-group">
                                <label htmlFor="causale">Causale</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Causale"
                                    id="causale"
                                    value={datiLocal?.causale || ''}
                                    onChange={(event) => setDatiLocal({ ...datiLocal, causale: event.target.value })}
                                    disabled
                                >
                                </input>
                            </div>
                            <div className="form-group">
                                <label htmlFor="realmRole">Numero di ore</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    placeholder="Numero di ore"
                                    id="numeroOre"
                                    value={datiLocal?.numeroOre || ''}
                                    onChange={(event) => setDatiLocal({ ...datiLocal, numeroOre: event.target.value })}
                                >
                                </input>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={(event) => {
                            try {
                                event.preventDefault();
                                dispatch({ type: 'SET_DATA', spinner: true });
                                let security = JSON.parse(sessionStorage.getItem('security'));
                                axios({
                                    method: 'POST',
                                    headers: {
                                        'Accept': 'application/json, text/plain, */*',
                                        'Authorization': 'Bearer ' + security.token
                                    },
                                    url: serverUrl + '/aziende/' + aziendaId + '/dipendente/' + dipendenteId + '/fogliopresenze',  // aziendaId, dipendenteId
                                    data: { ...datiLocal, giorno: toISOString(datiLocal.giorno) }
                                })
                                    .then((response) => {
                                        toast("Operazione conclusa con successo");
                                        setDatiLocal({});
                                        dispatch({ type: 'RESET' });
                                    })
                                    .catch(error => {
                                        dispatch({ type: 'SET_DATA', spinner: false });
                                        console.error("[STF] error:", error);
                                        toast("Attenzione, operazione NON completata");
                                    });
                            } catch (error) {
                                console.error("[STF] error:", error);
                                toast("Attenzione, operazione NON completata");
                            }
                        }}><i className="fas fa-cloud-upload-alt"></i> Salva</button>

                    </div>
                </div>
            </div>
        </div>
    );
}