import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import axios from 'axios';
import './App.css';
import Header from './Header';

//export const serverUrl = "http://localhost:8082/be";
export const serverUrl = "/be";

export const isAdmin = (jwt) => jwt?.claims?.realm_access?.roles?.filter(item => item == 'SIMPLE_ADMIN').length > 0;

export const getYYYYMMDD = (str) => {
  try {
    let d = new Date(Date.parse(str));
    let day = d.getDate();
    let month = d.getMonth() + 1;
    let year = d.getFullYear();
    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = `0${month}`;
    }
    return year + '-' + month + '-' + day;
  } catch (error) {
    return '';
  }
}

export const getYYYYMMDDext = (str) => {
  try {
    let d = new Date(Date.parse(str));
    let day = d.getDate();
    let month = d.getMonth() + 1;
    let year = d.getFullYear();
    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = `0${month}`;
    }

    let dayOfWeek = ''
    switch (d.getDay()) {
      case 0:
        dayOfWeek = 'Domenica';
        break;
      case 1:
        dayOfWeek = 'Lunedi';
        break;
      case 2:
        dayOfWeek = 'Martedi';
        break;
      case 3:
        dayOfWeek = 'Mercoledi';
        break;
      case 4:
        dayOfWeek = 'Giovedi';
        break;
      case 5:
        dayOfWeek = 'Venerdi';
        break;
      case 6:
        dayOfWeek = 'Sabato';
        break;
    }
    return year + '-' + month + '-' + day + ' ' + dayOfWeek;
  } catch (error) {
    return '';
  }
}

export const toISOString = (str) => {
  try {
    let d = new Date(Date.parse(str));
    return d.toISOString();
  } catch (error) {
    return null;
  }
}

export function Spinner() {
  const spinner = useSelector(state => state?.spinner);
  //console.log("[STF] Spinner", spinner)
  if (spinner) {
    return (
      <div className="spinner-border text-primary spinner" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    );
  }
  return null;
}

function App() {
  const progetti = useSelector(state => state?.progetti);
  const aziende = useSelector(state => state?.aziende);
  const dispatch = useDispatch();

  const getAzienda = (id) => {
    return aziende?.filter(item => item.id == id)[0]
  }

  useEffect(() => {
    if (progetti === null) {
      if (sessionStorage.getItem('security')) {
        let security = JSON.parse(sessionStorage.getItem('security'));
        axios({
          method: 'GET',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Authorization': 'Bearer ' + security.token
          },
          url: serverUrl + '/progetti',
        })
          .then((response) => {
            //let { progetti } = response.data;
            dispatch({ type: 'SET_DATA', ...response.data });
          })
          .catch(error => {
            console.error("[STF] error:", error);
            sessionStorage.removeItem('security');
            dispatch({ type: 'RESET' });
          });
      }
    }
  });

  return (
    <>
      <Header />

      <div className="container">
        <div className="row">
          {
            progetti?.map((progetto, index) =>
              <div key={'1_' + index} className="col-md-6 col-sm-12">
                <div key={'2_' + index} className="card">
                  <div key={'3_' + index} className="card-body">
                    <h5 key={'4_' + index} className="card-title">azienda <Link key={'5b_' + index} to={"/aziende/" + progetto?.aziendaId}>{getAzienda(progetto?.aziendaId)?.ragioneSociale}</Link></h5>
                    <h6 key={'5_' + index} className="card-subtitle mb-2 text-body-secondary">progetto <Link key={'5b_' + index} to={"/progetti/" + progetto.id}>{progetto.nome}</Link></h6>
                    <p key={'6_' + index} className="card-text">{progetto.descrizione}</p>
                    {progetto
                      ?.attivitaEntityList
                      ?.sort((a, b) => a.id - b.id)
                      ?.map((attivita) => <div key={'attivita_' + attivita.id}>attivita' <Link to={"/progetti/" + progetto.id + "/attivita/" + attivita.id}>{attivita.nome}</Link><br /></div>)}
                  </div>
                </div>
              </div>)
          }
        </div>
      </div>
    </>
  );
}

export default App;
