import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createHashRouter,
  RouterProvider,
} from "react-router-dom";
import { legacy_createStore as createStore } from 'redux';
import { Provider } from 'react-redux'
import './index.css';
import ErrorPage from "./ErrorPage";
import Aziende from "./Aziende";
import Azienda from "./Azienda";
import Progetto from "./Progetto";
import Attivita from "./Attivita";
import Dipendente from './Dipendente';
import theReducer from './TheReducer';
import Auth from './Auth';
import reportWebVitals from './reportWebVitals';

window.auth = new Auth({
  "vendor": "keycloak",
  "authorize": "/auth/realms/simplerealm/protocol/openid-connect/auth",
  "logout": "/auth/realms/simplerealm/protocol/openid-connect/logout",
  "token": "/auth/realms/simplerealm/protocol/openid-connect/token",
  "userinfo": "/auth/realms/simplerealm/protocol/openid-connect/userinfo",
  "introspection_endpoint": "/auth/realms/simplerealm/protocol/openid-connect/token/introspect",
  "clientId": "simpleclient",
  "code_challenge_method": "S256",
  "expires": "240000"
});

// window.auth = new Auth({
//   "vendor": "keycloak",
//   "authorize": "https://simpleapp.morronistefano.online/auth/realms/simplerealm/protocol/openid-connect/auth",
//   "logout": "https://simpleapp.morronistefano.online/auth/realms/simplerealm/protocol/openid-connect/logout",
//   "token": "https://simpleapp.morronistefano.online/auth/realms/simplerealm/protocol/openid-connect/token",
//   "userinfo": "https://simpleapp.morronistefano.online/auth/realms/simplerealm/protocol/openid-connect/userinfo",
//   "introspection_endpoint": "https://simpleapp.morronistefano.online/auth/realms/simplerealm/protocol/openid-connect/token/introspect",
//   "clientId": "simpleclient",
//   "code_challenge_method": "S256",
//   "expires": "240000"
// });

window.auth.handleAuthentication();
window.auth.refreshToken();

const router = createHashRouter([
  {
    path: "/",
    element: <Aziende />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/aziende",
    element: <Aziende />,
  },
  {
    path: "/aziende/:aziendaId",
    element: <Azienda />,
  },
  {
    path: "/aziende/:aziendaId/dipendente/:dipendenteId",
    element: <Dipendente />,
  },
  {
    path: "/aziende/:aziendaId/progetto/:progettoId",
    element: <Progetto />,
  },
  {
    path: "/aziende/:aziendaId/progetto/:progettoId/attivita/:attivitaId",
    element: <Attivita />,
  },
]);

const store = createStore(theReducer);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
