import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Header from './Header';
import { serverUrl, isAdmin, getYYYYMMDDext, getYYYYMMDD, toISOString, Spinner } from './App';

export function NuovaAltraAttivitaModal(props) {
    const { aziendaId, dipendenteId } = useParams();
    const [datiLocal, setDatiLocal] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        window.$("#giorno1").datepicker({
            timepicker: false,
            dateFormat: 'yy-mm-dd',
        }).on('change', (event) => setDatiLocal({ ...datiLocal, giorno: event.target.value }));
    });

    return (
        <div className="modal fade" id={props.id} tabIndex="-1" aria-labelledby={props.id + 'Label'} aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id={props.id + 'Label'}>Aggiungi altra attivita</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="form-group">
                                <label htmlFor="giorno1">Giorno</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Giorno"
                                    id="giorno1"
                                    value={datiLocal?.giorno || ''}
                                    onChange={(event) => setDatiLocal({ ...datiLocal, giorno: event.target.value })}
                                >
                                </input>
                            </div>
                            <div className="form-group">
                                <label htmlFor="motivoAssenza">Attivita</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Attivita"
                                    id="motivoAssenza"
                                    value={datiLocal?.motivoAssenza || ''}
                                    onChange={(event) => setDatiLocal({ ...datiLocal, motivoAssenza: event.target.value })}
                                >
                                </input>
                            </div>
                            <div className="form-group">
                                <label htmlFor="numeroOreAssenza">Numero di ore</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    placeholder="Numero di ore"
                                    id="numeroOreAssenza"
                                    value={datiLocal?.numeroOreAssenza || ''}
                                    onChange={(event) => setDatiLocal({ ...datiLocal, numeroOreAssenza: event.target.value })}
                                >
                                </input>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={(event) => {
                            try {
                                event.preventDefault();
                                dispatch({ type: 'SET_DATA', spinner: true });
                                let security = JSON.parse(sessionStorage.getItem('security'));
                                axios({
                                    method: 'POST',
                                    headers: {
                                        'Accept': 'application/json, text/plain, */*',
                                        'Authorization': 'Bearer ' + security.token
                                    },
                                    url: serverUrl + '/aziende/' + aziendaId + '/dipendente/' + dipendenteId + '/assenza',  // aziendaId, dipendenteId
                                    data: { ...datiLocal, giorno: toISOString(datiLocal.giorno) }
                                })
                                    .then((response) => {
                                        toast("Operazione conclusa con successo");
                                        setDatiLocal({});
                                        dispatch({ type: 'RESET' });
                                    })
                                    .catch(error => {
                                        dispatch({ type: 'SET_DATA', spinner: false });
                                        console.error("[STF] error:", error);
                                        toast("Attenzione, operazione NON completata");
                                    });
                            } catch (error) {
                                dispatch({ type: 'SET_DATA', spinner: false });
                                console.error("[STF] error:", error);
                                toast("Attenzione, operazione NON completata");
                            }
                        }}><i className="fas fa-cloud-upload-alt"></i> Salva</button>

                    </div>
                </div>
            </div>
        </div>
    );
}

export function ModificaAltraAttivitaModal(props) {
    const { aziendaId, dipendenteId } = useParams();
    const [datiLocal, setDatiLocal] = useState(props?.datiLocal);
    const dispatch = useDispatch();

    useEffect(() => {
        window.$("#giorno2").datepicker({
            timepicker: false,
            dateFormat: 'yy-mm-dd',
        }).on('change', (event) => setDatiLocal({ ...datiLocal, giorno: event.target.value }));
    });

    return (
        <div className="modal fade" id={props.id} tabIndex="-1" aria-labelledby={props.id + 'Label'} aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id={props.id + 'Label'}>Modifica Assenza</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="form-group">
                                <label htmlFor="giorno2">Giorno</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Giorno"
                                    id="giorno2"
                                    value={datiLocal?.giorno || ''}
                                    onChange={(event) => setDatiLocal({ ...datiLocal, giorno: event.target.value })}
                                >
                                </input>
                            </div>
                            <div className="form-group">
                                <label htmlFor="motivoAssenza">Causale</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Causale"
                                    id="motivoAssenza"
                                    value={datiLocal?.motivoAssenza || ''}
                                    onChange={(event) => setDatiLocal({ ...datiLocal, motivoAssenza: event.target.value })}
                                >
                                </input>
                            </div>
                            <div className="form-group">
                                <label htmlFor="numeroOreAssenza">Numero di ore</label>
                                <input
                                    className="form-control"
                                    type="number"
                                    placeholder="Numero di ore"
                                    id="numeroOreAssenza"
                                    value={datiLocal?.numeroOreAssenza || ''}
                                    onChange={(event) => setDatiLocal({ ...datiLocal, numeroOreAssenza: event.target.value })}
                                >
                                </input>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={(event) => {
                            try {
                                event.preventDefault();
                                dispatch({ type: 'SET_DATA', spinner: true });
                                let security = JSON.parse(sessionStorage.getItem('security'));
                                axios({
                                    method: 'POST',
                                    headers: {
                                        'Accept': 'application/json, text/plain, */*',
                                        'Authorization': 'Bearer ' + security.token
                                    },
                                    url: serverUrl + '/aziende/' + aziendaId + '/dipendente/' + dipendenteId + '/assenza',  // aziendaId, dipendenteId
                                    data: { ...datiLocal, giorno: toISOString(datiLocal.giorno) }
                                })
                                    .then((response) => {
                                        toast("Operazione conclusa con successo");
                                        setDatiLocal({});
                                        dispatch({ type: 'RESET' });
                                    })
                                    .catch(error => {
                                        dispatch({ type: 'SET_DATA', spinner: false });
                                        console.error("[STF] error:", error);
                                        toast("Attenzione, operazione NON completata");
                                    });
                            } catch (error) {
                                console.error("[STF] error:", error);
                                toast("Attenzione, operazione NON completata");
                            }
                        }}><i className="fas fa-cloud-upload-alt"></i> Salva</button>

                    </div>
                </div>
            </div>
        </div>
    );
}