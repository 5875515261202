import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { serverUrl, isAdmin, getYYYYMMDDext, getYYYYMMDD, toISOString, Spinner } from './App';
import queryString from 'query-string';

function CaricaFoglioPresenze(props) {
    const { aziendaId } = useParams();
    const [datiLocal, setDatiLocal] = useState(localStorage.getItem("CaricaFoglioPresenze") ? JSON.parse(localStorage.getItem("CaricaFoglioPresenze")) : { formato: "zucchetti" }); //{ formato: "zucchetti" });
    const dispatch = useDispatch();
    return (
        <div className="modal fade" id={props.id} tabIndex="-1" aria-labelledby={props.id + 'Label'} aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id={props.id + 'Label'}>Carica Foglio Presenze</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        <form id="uploadForm">
                            <fieldset className="form-group">
                                <div className="row">
                                    <legend className="col-form-label col-md-4 pt-0">Formato</legend>
                                    <div className="col-md-8">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" id="gridRadios1" value="zucchetti"
                                                onChange={(event) => setDatiLocal({ ...datiLocal, formato: event.target.value })}
                                                checked={datiLocal?.formato === 'zucchetti'}
                                            />
                                            <label className="form-check-label" htmlFor="gridRadios1">Zucchetti</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" id="gridRadios2" value="xxxx" disabled
                                                onChange={(event) => setDatiLocal({ ...datiLocal, formato: event.target.value })}
                                                checked={datiLocal?.formato === 'xxxx'}
                                            />
                                            <label className="form-check-label" htmlFor="gridRadios2">...</label>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <div className="form-group row">
                                <label htmlFor="nome" className="col-md-4 col-form-label">Causali per Ore Ordinarie</label>
                                <div className="col-md-2">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        id="oreordinariecausale1"
                                        value={datiLocal?.oreordinariecausale1 || ''}
                                        onChange={(event) => setDatiLocal({ ...datiLocal, oreordinariecausale1: event.target.value })}
                                    >
                                    </input>
                                </div>
                                <div className="col-md-2">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="oreordinariecausale2"
                                        value={datiLocal?.oreordinariecausale2 || ''}
                                        onChange={(event) => setDatiLocal({ ...datiLocal, oreordinariecausale2: event.target.value })}
                                    >
                                    </input>
                                </div>
                                <div className="col-md-2">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="oreordinariecausale3"
                                        value={datiLocal?.oreordinariecausale3 || ''}
                                        onChange={(event) => setDatiLocal({ ...datiLocal, oreordinariecausale3: event.target.value })}
                                    >
                                    </input>
                                </div>
                                <div className="col-md-2">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="oreordinariecausale4"
                                        value={datiLocal?.oreordinariecausale4 || ''}
                                        onChange={(event) => setDatiLocal({ ...datiLocal, oreordinariecausale4: event.target.value })}
                                    >
                                    </input>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="nome" className="col-md-4 col-form-label">Causali per Malattia</label>
                                <div className="col-md-2">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        id="malattiacausale1"
                                        value={datiLocal?.malattiacausale1 || ''}
                                        onChange={(event) => setDatiLocal({ ...datiLocal, malattiacausale1: event.target.value })}
                                    >
                                    </input>
                                </div>
                                <div className="col-md-2">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="malattiacausale2"
                                        value={datiLocal?.malattiacausale2 || ''}
                                        onChange={(event) => setDatiLocal({ ...datiLocal, malattiacausale2: event.target.value })}
                                    >
                                    </input>
                                </div>
                                <div className="col-md-2">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="malattiacausale3"
                                        value={datiLocal?.malattiacausale3 || ''}
                                        onChange={(event) => setDatiLocal({ ...datiLocal, malattiacausale3: event.target.value })}
                                    >
                                    </input>
                                </div>
                                <div className="col-md-2">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="malattiacausale4"
                                        value={datiLocal?.malattiacausale4 || ''}
                                        onChange={(event) => setDatiLocal({ ...datiLocal, malattiacausale4: event.target.value })}
                                    >
                                    </input>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="nome" className="col-md-4 col-form-label">Causali per Ferie</label>
                                <div className="col-md-2">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        id="feriecausale1"
                                        value={datiLocal?.feriecausale1 || ''}
                                        onChange={(event) => setDatiLocal({ ...datiLocal, feriecausale1: event.target.value })}
                                    >
                                    </input>
                                </div>
                                <div className="col-md-2">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="feriecausale2"
                                        value={datiLocal?.feriecausale2 || ''}
                                        onChange={(event) => setDatiLocal({ ...datiLocal, feriecausale2: event.target.value })}
                                    >
                                    </input>
                                </div>
                                <div className="col-md-2">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="feriecausale3"
                                        value={datiLocal?.feriecausale3 || ''}
                                        onChange={(event) => setDatiLocal({ ...datiLocal, feriecausale3: event.target.value })}
                                    >
                                    </input>
                                </div>
                                <div className="col-md-2">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="feriecausale4"
                                        value={datiLocal?.feriecausale4 || ''}
                                        onChange={(event) => setDatiLocal({ ...datiLocal, feriecausale4: event.target.value })}
                                    >
                                    </input>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="nome" className="col-md-4 col-form-label">Causali per Permessi</label>
                                <div className="col-md-2">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        id="permessicausale1"
                                        value={datiLocal?.permessicausale1 || ''}
                                        onChange={(event) => setDatiLocal({ ...datiLocal, permessicausale1: event.target.value })}
                                    >
                                    </input>
                                </div>
                                <div className="col-md-2">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="permessicausale2"
                                        value={datiLocal?.permessicausale2 || ''}
                                        onChange={(event) => setDatiLocal({ ...datiLocal, permessicausale2: event.target.value })}
                                    >
                                    </input>
                                </div>
                                <div className="col-md-2">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="permessicausale3"
                                        value={datiLocal?.permessicausale3 || ''}
                                        onChange={(event) => setDatiLocal({ ...datiLocal, permessicausale3: event.target.value })}
                                    >
                                    </input>
                                </div>
                                <div className="col-md-2">
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="permessicausale4"
                                        value={datiLocal?.permessicausale4 || ''}
                                        onChange={(event) => setDatiLocal({ ...datiLocal, permessicausale4: event.target.value })}
                                    >
                                    </input>
                                </div>
                            </div>
                            <br/>
                            <br/>
                            <div className="form-group row">
                                <input type="file" name="file" />
                            </div>
                        </form>
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={(event) => {
                            try {
                                event.preventDefault();
                                dispatch({ type: 'SET_DATA', spinner: true });
                                localStorage.setItem("CaricaFoglioPresenze", JSON.stringify(datiLocal));
                                let security = JSON.parse(sessionStorage.getItem('security'));
                                const uploadForm = document.getElementById('uploadForm');
                                const selectedFile = uploadForm.elements.file.files[0];
                                const formData = new FormData();
                                formData.append('thefile', selectedFile);
                                axios({
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'multipart/form-data',
                                        'Authorization': 'Bearer ' + security.token
                                    },
                                    url: serverUrl + '/aziende/' + aziendaId + '/caricafogliopresenze?' + queryString.stringify(datiLocal),
                                    data: formData
                                })
                                    .then((response) => {
                                        toast("Operazione conclusa con successo");
                                        dispatch({ type: 'RESET' });
                                    })
                                    .catch(error => {
                                        dispatch({ type: 'SET_DATA', spinner: false });
                                        console.error("[STF] error:", error);
                                        toast("Attenzione, operazione NON completata");
                                    });
                            } catch (error) {
                                dispatch({ type: 'SET_DATA', spinner: false });
                                console.error("[STF] error:", error);
                                toast("Attenzione, operazione NON completata");
                            }
                        }}><i className="fas fa-cloud-upload-alt"></i> Carica</button>

                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CaricaFoglioPresenze;