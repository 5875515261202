import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './App.css';
import { serverUrl, isAdmin, getYYYYMMDDext, getYYYYMMDD, toISOString, Spinner } from './App';


function Login() {
  return (<button type="button" className="btn btn-primary" onClick={() => window.auth.login()}>Login</button>);
}

function Logout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <button type="button" className="btn btn-primary" onClick={() => {
      if (sessionStorage.getItem('security')) {
        let security = JSON.parse(sessionStorage.getItem('security'));
        window.auth.logout(security?.token, security?.refresh_token, null);
      }
      sessionStorage.removeItem('security');
      dispatch({ type: 'RESET' });
      navigate("/");
    }}>Logout</button>
  );
}

export default function Header() {
  return (
    <>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <img src="/logo.png" className="card-img-top" alt="..." />
          <a className="navbar-brand position-relative" href="#">SimpleApp
            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary">
              0.0.5
              <span className="visually-hidden">versione</span>
            </span>
          </a>


          {/*<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/">Home</Link>&nbsp;&nbsp;
              </li>
              <li className="nav-item">
                <Link to="/aziende">Aziende</Link>
              </li>
            </ul>
          </div>*/}

          {sessionStorage.getItem('security') != null ? <Logout /> : <Login />}
        </div>
      </nav>
    </>
  );
}

