import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Header from './Header';
import { serverUrl, isAdmin, getYYYYMMDDext, getYYYYMMDD, toISOString, Spinner } from './App';

function NuovaAttivitaModal(props) {
  const { aziendaId, progettoId } = useParams();
  const [attivitaLocal, setAttivitaLocal] = useState({ oreApprovateQualificaAlta: 0, oreApprovateQualificaMedia: 0, oreApprovateQualificaBassa: 0 });
  const dispatch = useDispatch();

  useEffect(() => {
    window.$("#dataInizio").datepicker({
      timepicker: false,
      dateFormat: 'yy-mm-dd',
    }).on('change', (event) => setAttivitaLocal({ ...attivitaLocal, dataInizio: event.target.value }));

    window.$("#dataFine").datepicker({
      timepicker: false,
      dateFormat: 'yy-mm-dd',
    }).on('change', (event) => setAttivitaLocal({ ...attivitaLocal, dataFine: event.target.value }));
  });

  return (
    <div className="modal fade" id={props.id} tabIndex="-1" aria-labelledby={props.id + 'Label'} aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id={props.id + 'Label'}>Nuova attivita</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group row">
                <label htmlFor="nome" className="col-md-4 col-form-label">Identificativo</label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Identificativo"
                    id="nome"
                    value={attivitaLocal?.nome || ''}
                    onChange={(event) => setAttivitaLocal({ ...attivitaLocal, nome: event.target.value })}
                  >
                  </input>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="descrizione">Descrizione</label>
                <textarea
                  className="form-control"
                  id="descrizione"
                  rows="3"
                  value={attivitaLocal?.descrizione || ''}
                  onChange={(event) => setAttivitaLocal({ ...attivitaLocal, descrizione: event.target.value })}
                >
                </textarea>
              </div>

              <div className="row">
                <div className="form-group col-md-4">
                  <label htmlFor="oreApprovateQualificaAlta">Ore approvate qualifica alta</label>
                  <input
                    type="number"
                    className="form-control"
                    id="oreApprovateQualificaAlta"
                    value={attivitaLocal?.oreApprovateQualificaAlta || 0}
                    onChange={(event) => setAttivitaLocal({ ...attivitaLocal, oreApprovateQualificaAlta: event.target.value })}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="oreApprovateQualificaMedia">Ore approvate qualifica media</label>
                  <input
                    type="number"
                    className="form-control"
                    id="oreApprovateQualificaMedia"
                    value={attivitaLocal?.oreApprovateQualificaMedia || 0}
                    onChange={(event) => setAttivitaLocal({ ...attivitaLocal, oreApprovateQualificaMedia: event.target.value })}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="oreApprovateQualificaBassa">Ore approvate qualifica bassa</label>
                  <input
                    type="number"
                    className="form-control"
                    id="oreApprovateQualificaBassa"
                    value={attivitaLocal?.oreApprovateQualificaBassa || 0}
                    onChange={(event) => setAttivitaLocal({ ...attivitaLocal, oreApprovateQualificaBassa: event.target.value })}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="nome" className="col-md-4 col-form-label">Data Inizio</label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="YYYY-MM-DD"
                    id="dataInizio"
                    value={attivitaLocal?.dataInizio || ''}
                    maxLength="10"
                    onChange={(event) => setAttivitaLocal({ ...attivitaLocal, dataInizio: event.target.value })}
                  >
                  </input>
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="nome" className="col-md-4 col-form-label">Data Fine</label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="YYYY-MM-DD"
                    id="dataFine"
                    value={attivitaLocal?.dataFine || ''}
                    onChange={(event) => setAttivitaLocal({ ...attivitaLocal, dataFine: event.target.value })}
                  >
                  </input>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={(event) => {
              event.preventDefault();
              dispatch({ type: 'SET_DATA', spinner: true });
              let security = JSON.parse(sessionStorage.getItem('security'));
              let data = { ...attivitaLocal, dataInizio: toISOString(attivitaLocal.dataInizio), dataFine: toISOString(attivitaLocal.dataFine) }
              axios({
                method: 'POST',
                headers: {
                  'Accept': 'application/json, text/plain, */*',
                  'Authorization': 'Bearer ' + security.token
                },
                url: serverUrl + '/aziende/' + aziendaId + '/progetto/' + progettoId + '/attivita',
                data
              })
                .then((response) => {
                  setAttivitaLocal({});
                  dispatch({ type: 'RESET' });
                })
                .catch(error => {
                  console.error("[STF] error:", error);
                  toast("Attenzione, operazione NON completata");
                  dispatch({ type: 'SET_DATA', spinner: false });
                });
            }}><i className="fas fa-cloud-upload-alt"></i> Salva</button>
          </div>
        </div>
      </div>
    </div>
  );
}

function Progetto() {
  const { aziendaId, progettoId } = useParams();
  const aziende = useSelector(state => state?.aziende);
  const azienda = aziende?.filter(item => item.id == aziendaId)[0];
  const progetto = azienda?.progettiList?.filter(item => item.id == progettoId)[0];
  const [progettoLocal, setProgettoLocal] = useState({ ...progetto, dataConsolidamento: progetto?.dataConsolidamento === null ? '' : getYYYYMMDD(progetto?.dataConsolidamento) });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (aziende === null) {
      if (sessionStorage.getItem('security')) {
        dispatch({ type: 'SET_DATA', spinner: true });
        let security = JSON.parse(sessionStorage.getItem('security'));
        axios({
          method: 'GET',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Authorization': 'Bearer ' + security.token
          },
          url: serverUrl + '/aziende'
        })
          .then((response) => {
            //let { progetti } = response.data;
            dispatch({ type: 'SET_DATA', spinner: false, ...response.data });
          })
          .catch(error => {
            console.error("[STF] error:", error);
            dispatch({ type: 'SET_DATA', spinner: false });
          });
      }
    }
  });

  useEffect(() => {
    setProgettoLocal({ ...progetto, dataConsolidamento: progetto?.dataConsolidamento === null ? '' : getYYYYMMDD(progetto?.dataConsolidamento) });
  }, [progetto]);

  useEffect(() => {
    window.$("#dataConsolidamento").datepicker({
      timepicker: false,
      dateFormat: 'yy-mm-dd',
    }).on('change', (event) => setProgettoLocal({ ...progettoLocal, dataConsolidamento: event.target.value }));
  });

  return (
    <>
      <Header />
      <ToastContainer />
      <Spinner />
      <nav aria-label="breadcrumb">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
            <li className="breadcrumb-item" aria-current="page"><Link to={"/aziende/" + azienda?.id}>azienda {azienda?.ragioneSociale}</Link></li>
            <li className="breadcrumb-item" aria-current="page">progetto {progetto?.nome}</li>
          </ol>
        </div>
      </nav>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Progetto</h5>
                <form>
                  <div className="form-group">
                    <label htmlFor="nome">Identificativo</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Identificativo"
                      id="nome"
                      value={progettoLocal?.nome || ''}
                      onChange={(event) => setProgettoLocal({ ...progettoLocal, nome: event.target.value })}
                    >
                    </input>
                  </div>
                  <div className="form-group">
                    <label htmlFor="descrizione">Descrizione</label>
                    <textarea
                      className="form-control"
                      id="descrizione"
                      rows="3"
                      value={progettoLocal?.descrizione || ''}
                      onChange={(event) => setProgettoLocal({ ...progettoLocal, descrizione: event.target.value })}
                    >
                    </textarea>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="sede" className="col-md-4 col-form-label">Sede</label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Sede"
                        id="sede"
                        value={progettoLocal?.sede || ''}
                        onChange={(event) => setProgettoLocal({ ...progettoLocal, sede: event.target.value })}
                      >
                      </input>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="decretoMise" className="col-md-4 col-form-label">Decreto Mise</label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Decreto Mise"
                        id="decretoMise"
                        value={progettoLocal?.decretoMise || ''}
                        onChange={(event) => setProgettoLocal({ ...progettoLocal, decretoMise: event.target.value })}
                      >
                      </input>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="dataConsolidamento" className="col-md-4 col-form-label">Fino a quale data vanno considerati consolidati i timesheet?</label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="YYYY-MM-DD"
                        id="dataConsolidamento"
                        value={progettoLocal?.dataConsolidamento || ''}
                        onChange={(event) => setProgettoLocal({ ...progettoLocal, dataConsolidamento: event.target.value })}
                      >
                      </input>
                    </div>
                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn btn-primary" onClick={(event) => {
                      event.preventDefault();
                      dispatch({ type: 'SET_DATA', spinner: true });
                      let security = JSON.parse(sessionStorage.getItem('security'));
                      let data = { ...progettoLocal, dataConsolidamento: toISOString(progettoLocal.dataConsolidamento) };
                      axios({
                        method: 'POST',
                        headers: {
                          'Accept': 'application/json, text/plain, */*',
                          'Authorization': 'Bearer ' + security.token
                        },
                        url: serverUrl + '/aziende/' + aziendaId + '/progetto/',
                        data
                      })
                        .then((response) => {
                          toast("Operazione conclusa con successo");
                          dispatch({ type: 'RESET' });
                        })
                        .catch(error => {
                          console.error("[STF] error:", error);
                          toast("Attenzione, operazione NON completata");
                          dispatch({ type: 'SET_DATA', spinner: false });
                        });
                    }}><i className="fas fa-cloud-upload-alt"></i> Salva</button>
                    &nbsp;&nbsp;
                    <button type="submit" className="btn btn-primary" onClick={(event) => {
                      event.preventDefault();
                      dispatch({ type: 'SET_DATA', spinner: true });
                      let security = JSON.parse(sessionStorage.getItem('security'));
                      axios({
                        method: 'DELETE',
                        headers: {
                          'Accept': 'application/json, text/plain, */*',
                          'Authorization': 'Bearer ' + security.token
                        },
                        url: serverUrl + '/aziende/' + aziendaId + '/progetto/' + progettoId,
                      })
                        .then((response) => {
                          toast("Operazione conclusa con successo");
                          dispatch({ type: 'RESET' });
                          navigate('/aziende/' + aziendaId);
                        })
                        .catch(error => {
                          console.error("[STF] error:", error);
                          toast("Attenzione, operazione NON completata");
                          dispatch({ type: 'SET_DATA', spinner: false });
                        });
                    }}><i className="fa fa-trash"></i> Elimina questo progetto</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Attivita'</h5>
                <ul className="list-group list-group-flush">
                  {progetto
                    ?.attivitaEntityList
                    ?.sort((a, b) => a.id - b.id)
                    ?.map((item, index) =>
                      <li key={'1_' + index} className="list-group-item">
                        <Link key={'2_' + index} to={"/aziende/" + azienda?.id + "/progetto/" + progetto?.id + "/attivita/" + item.id}>{item.nome}</Link>
                        {item.warning && <>&nbsp;&nbsp;<span className="badge text-bg-danger">{item.warning}</span></>}
                      </li>
                    )}
                </ul>
                <NuovaAttivitaModal id={"nuovaAttivitaModal"} />
                <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target={"#nuovaAttivitaModal"}>
                  Nuova attivita'
                </button>
              </div>
            </div>
          </div>



        </div>
      </div>
    </>
  );
}

export default Progetto;
